import React, { useEffect, useState } from 'react';

const Loader = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
      const timeout = setTimeout(() => {
          setVisible(false); 
      }, 60000);

      return () => clearTimeout(timeout);
  }, []);

  if (!visible) return null; 

  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
